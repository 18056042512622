
<nav class="navbar shadow navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
  <a class="navbar-brand my-0 mr-md-auto" [routerLink]="['/']">
    <img class="logo" src="/assets/img/Bpost_Logo.svg" alt="logo">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02"
    aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">

    <ul *ngIf="userInfo" class="navbar-nav me-auto mb-2 mb-lg-0">
      <li class="nav-item dropdown" routerLinkActive="active" [ngClass]="{'noneProperty':!showDeliverypointHeader}">
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{'Delivery Points'| translate}}</a>
        <div class="dropdown-menu">
          <a class="dropdown-item" [routerLink]="['/deliverypoints']"
            [appViewController]="{deliveryPoints:'showList'}">{{'List'|translate}}</a>
          <a class="dropdown-item" [routerLink]="['/locker-upload']"
            [appViewController]="{deliveryPoints:'showOnboard'}">{{'Onboard'|translate}}</a>
          <div class="dropdown-divider" *ngIf="partnerId == 1 && (userInfo?.permissions?.deliveryPoints?.showOnboard || userInfo?.permissions?.deliveryPoints?.showList) && (userInfo?.permissions?.staff?.postmanCode)"></div>
          <a class="dropdown-item" *ngIf="partnerId == 1" [routerLink]="['/postman']"
            [appViewController]="{staff:'postmanCode'}">{{'Generate Login Code'|translate}}</a>
        </div>
      </li>

      <li class="nav-item dropdown" routerLinkActive="active" [ngClass]="{'noneProperty':!showparcelHeader}">
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{'Parcels'| translate}}
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" [routerLink]="['/parcels']"
            [appViewController]="{parcels:'list'}">{{'List'|translate}}</a>
          <div class="dropdown-divider" *ngIf="(userInfo?.permissions?.parcels?.list) && (userInfo?.permissions?.parcels?.reserveLastmile || userInfo?.permissions?.parcels?.reserveFirstmile)"></div>
          <a class="dropdown-item" [routerLink]="['/reservation']"
            [appViewController]="{parcels:'reserveLastmile'}">{{'Reserve Last Mile'|translate}}</a>
          <a class="dropdown-item" [routerLink]="['/reservation/firstmile']"
            [appViewController]="{parcels:'reserveFirstmile'}">{{'Reserve First Mile'|translate}}</a>

        </div>
      </li>

      <li class="nav-item dropdown" routerLinkActive="active" [ngClass]="{'noneProperty':!showUserHeader}">
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{'Users'|translate}}
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" [routerLink]="['/user/search']"
            [appViewController]="{users:'showList'}">{{'List' | translate}}</a>
          <a class="dropdown-item" [routerLink]="['/user/details']"
            [appViewController]="{users:'addUser'}">{{'Add' | translate}}</a>
          <div class="dropdown-divider" *ngIf="(userInfo?.permissions?.users?.showList || userInfo?.permissions?.users?.addUser) && (userInfo?.permissions?.users?.showGroup)"></div>
          <a class="dropdown-item" [routerLink]="['/user/usergroup']"
            [appViewController]="{users:'showGroup'}">{{'Groups' | translate}}</a>
          <div class="dropdown-divider" *ngIf="partnerId == 1 && (userInfo?.permissions?.users?.showGroup && userInfo?.permissions?.staff?.postmanCode)"></div>
          <a class="dropdown-item" *ngIf="partnerId == 1" [routerLink]="['/maintenanceUser']"
            [appViewController]="{staff:'postmanCode'}">{{'Maintenance Users' | translate}}</a>
        </div>
      </li>
      <li
        class="nav-item dropdown"
        *ngIf="partnerId == 1"
        routerLinkActive="active"
        [ngClass]="{'noneProperty':!showpartnerHeader}"
      >
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{'Partners'|translate}}</a>
        <div class="dropdown-menu">
          <a [appViewController]="{partner:'showList'}" class="dropdown-item"
            [routerLink]="['/partners']">{{'List'|translate}}</a>
          <a [appViewController]="{partner:'addPartner'}" class="dropdown-item"
            [routerLink]="['/createPartners']">{{'Add'|translate}}</a>
        </div>
      </li>

        <a class="nav-link" [routerLink]="['/partnertools']"
          [appViewController]="{partnerTool:'viewPartnerTool'}">{{'Partner Tool'|translate}}</a>

      <li class="nav-item dropdown" *ngIf="partnerId == 1" routerLinkActive="active"
        [ngClass]="{'noneProperty':!showReportHeader}">
        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{'Reports'|translate}}</a>
        <div class="dropdown-menu">
          <a class="dropdown-item" [routerLink]="['/lockerdownreport']"
            [appViewController]="{report:'downTimeReport'}">{{'Downtimes'|translate}}</a>
          <a class="dropdown-item" [routerLink]="['/fm-failed']"
            [appViewController]="{report:'firstmileFailed'}">{{'First Miles Failed'|translate}}</a>
          <a class="dropdown-item" [routerLink]="['/userOperation']"
            [appViewController]="{report:'userOpsReport'}">{{'User Operations'|translate}}</a>
        </div>
      </li>
    </ul>


    <div class="d-flex">
    <app-langgtoggle ></app-langgtoggle>
    <div class="dropdown" *ngIf="userInfo && authorized">
      <input src="/assets/img/user2.svg" width="50" height="50" alt="User" class="btn btn-sm dropdown-toggle me-2 "
        type="image" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
        <li>
          <a class="dropdown-item">{{email}}</a>
        </li>
      </ul>
    </div>
    <button *ngIf="!userInfo" class="btn btn-outline-danger mx-2" id="handle-sign-in"
      [routerLink]="['/signin']">{{'Sign in' | translate}}</button>
    <button *ngIf="userInfo" class="btn btn-outline-danger mx-2" id="handle-sign-in"
      (click)='signOut()'>{{'Sign out'|translate}}</button>
    </div>
  </div>
</div>
</nav>

<app-loader *ngIf="showSpinner"></app-loader>
<script>
  $(".ul li").on("click", function () {
    $(".ul li").removeClass("active");
    $(this).addClass("active");
  });
</script>
