<div class="mx-5 mt-5" [appViewController]="{partnerTool:'viewPartnerTool'}">
    <h2 class="text-danger"> {{'Overview'|translate}}</h2>
    <div *ngIf="lockerNotFree" class="alert alert-danger w-50 ms-auto me-auto" role="alert">
        {{'Capacity is full'|translate}}
    </div>
    <form [formGroup]="partenOrderForm">
        <div class="row">
            <div class="col-lg-6 col-12">
                <div class="table-responsive">
                    <div class="mt-8 ms-4 me-5">
                        <table class="table table-bordered">
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th scope="" colspan="2">{{'Facts'|translate}}</th>
                                </tr>
                                <tr>
                                    <th scope="">{{'Status'|translate}}</th>
                                    <td>
                                        <div class="container">
                                            <div class="row">
                                                <div *ngIf="!reservationCheck"
                                                    class=" px-2 bg-success status"></div>
                                                <div *ngIf="reservationCheck" 
                                                    class=" px-2 bg-danger status"></div>
                                                <div class="col" *ngIf="editForm && id.action == 'process'">{{'Safe Reserved'|translate}}</div>
                                                <div class="col" *ngIf="editForm && id.action == 'vault'">
                                                    {{'Parcel delivered to the safe'|translate}}</div>
                                                <div class="col" *ngIf="editForm && id.action == 'history'">
                                                    {{'Package collected from the safe'|translate}}</div>
                                                <div class="col" *ngIf="!editForm">{{'Empty order'|translate}}</div>

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="">{{'Date'|translate}}</th>
                                    <td>{{overviewDate}}</td>
                                </tr>
                                <tr>
                                    <th scope="">{{'whenToCollect'|translate}}</th>
                                    <td> {{action=='edit'? "-" : whentocollectvalue}}</td>
                                </tr>
                                <tr>
                                 
                                    <th scope="" class="align-middle">{{'customerData'|translate}}</th>
                                    <td *ngIf="customerObj ">
                                        <div><b class="mr-2">{{'Name' | translate}}:</b>{{customerObj | fullName | translate }}</div>
                                        <div><b class="mr-2">{{'Email' | translate}}:</b>{{customerObj.Email ||'-'}}</div>
                                        <div><b class="mr-2">{{'Phone number' | translate}}:</b>{{customerObj.phoneNumber ||'-'}}</div>
                                    </td>
                                    <td *ngIf="!customerObj">{{'Anonymous'| translate}}</td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <div class="ms-4 me-5" *ngIf="!editForm">
                    <table class="table table-bordered">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th></th>
                                <td><button class="btn btn-danger"
                                        (click)="generatePdf()">{{'printBarcodes'|translate}}</button></td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>

            <div class="col-lg-6 col-12">
                <div class="table-responsive">
                    <table class="table table-bordered">
                        <caption></caption>
                        <thead>
                            <tr>
                                <th scope="" class="w-25" colspan="3">{{'placeAnOrder'|translate}}</th>
                            </tr>
                            <tr>
                                <th scope="" colspan="2">{{'numberOfPackages'|translate}}</th>
                                <td>
                                    <input type="text" class="form-control phone_screen_width" id="noPackage"
                                        formControlName="noPackage" [readonly]="true">
                                </td>
                            </tr>
                     
                            <tr>
                                <th colspan="2" scope="">{{'Reference'|translate}}</th>
                                <td>
                                    <input type="text" class="form-control w-50"
                                        formControlName="reference" id="reference" [readOnly]="editForm">
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2" scope="">{{'Location'|translate}}</th>
                                <td *ngIf="!editForm">
                                    <select class="form-select" formControlName="location" name="Location"
                                        id="location" (change)="setLocationName($event.target.value)" >
                                        <option *ngFor="let item of locationData" [value]="item.id">{{item.name}}
                                        </option>
                                    </select>
                                    <small *ngIf="submitted &&  formValidators.location.errors?.required"
                                    class="text-danger d-flex" role="alert">{{'location is a required field'|translate}}</small>
                                </td>
                                <td *ngIf="editForm">
                                    <select class="form-control" formControlName="location" name="Location"
                                        id="location" (change)="setLocationName($event.target.value)" [attr.disabled]="isEditablefields ? true :null">
                                        <option *ngFor="let item of locationData" [value]="item.id">{{item.name}}
                                        </option>
                                    </select>
                                    <small *ngIf="submitted &&  formValidators.location.errors?.required"
                                    class="text-danger d-flex" role="alert">{{'location is a required field'|translate}}</small>
                                </td>
                            </tr>
                     
                            <tr>
                                <th colspan="2" scope="">{{'reservePeriod'|translate}}</th>
                                <td>
                                    <div class="container">
                                        <div class="row">
                                        <div class="col-12 px-0">
                                        <div class="">
                                            
                                            <div class="input-group ps-0">
                                            <input type="text" formControlName="reservePeriodFrom"
                                                class="form-control" id="reservePeriod"
                                                #reserveDateFromInput bsDatepicker (bsValueChange)='fromdateChange($event)'
                                                [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY hh:mm',withTimepicker: true}"
                                                readonly disabled>
                                            <span class="input-group-text"  (click)="openDatePicker('reserveDateFromInput')">
                                                <i class="fas fa-calendar-alt"></i>
                                            </span>
                                          
                                            <span class="mt-2 mx-1"></span>
                                            <input formControlName="reservePeriodTo" type="text"
                                                class="form-control" id="reservePeriod"
                                                #reserveDateToInput bsDatepicker [minDate]='todayDate' [maxDate]='maxDate' (bsValueChange)='todateChange($event)'
                                                [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY hh:mm a',withTimepicker: true}"  >
                                            <span class="input-group-text"
                                                (click)="openDatePicker('reserveDateToInput')">
                                                <i class="fas fa-calendar-alt"></i>
                                            </span>
                                        </div>

                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                    
                                    <small
                                        *ngIf="submitted &&  (formValidators.reservePeriodTo.errors?.required||formValidators.reservePeriodFrom.errors?.required)"
                                        class="text-danger d-flex" role="alert">{{'Please fill both the dates'|translate}}</small>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2" scope="">{{'Barcode'|translate}}</th>
                                <td>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control" formControlName="barcode"  [attr.disabled]="disableFieldBarcode ? true : null"
                                                id="barcode" >
                                        </div>
                                        <div class="col-sm-6">
                                            <span>{{'leaveBlankToGenerate'|translate}}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2" scope="">{{'pickUpCode'|translate}}</th>
                                <td>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <input type="text" class="form-control" formControlName="customerBarcode"
                                                id="customerBarcode" maxlength="6"  [attr.disabled]="disableFields ? true : null">
                                            <small *ngIf="submitted &&  formValidators.customerBarcode.errors?.pattern"
                                            class="text-danger d-flex" role="alert">{{'Enter the pickup code in correct format'|translate}}</small>
                                        </div>

                                        <div class="col-sm-6">
                                            <span>{{'leaveBlankToGenerate'|translate}}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="2" scope="">{{'Package'|translate}}</th>
                                <td>
                                    <div>
                                        <div class="row ">
                                            <div class="col-4 mt-2">{{'Dimensions'|translate}}(LxWxH)</div>
                                            <div class="col-8">
                                                <input class="ml-md-5 d-inline form-control phone_screen_width"
                                                     id="length" formControlName="length"
                                                     (input)="onInputChange($event)" [attr.disabled]="isEditablefields ? true : null">
                                                <span>x</span>
                                                <input class="d-inline form-control phone_screen_width"
                                                    id="width" formControlName="width" 
                                                    (input)="onInputChange($event)"  [attr.disabled]="isEditablefields ? true : null">
                                                <span>x</span>
                                                <input class="d-inline form-control phone_screen_width"
                                                    id="height" formControlName="height"  
                                                    (input)="onInputChange($event)"  [attr.disabled]="isEditablefields ? true : null">
                                                <span>mm</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr *ngIf="!editForm">
                                <td colspan="3" class="text-center">
                                    <button class="btn btn-danger" type="submit"
                                        (click)="saveDetails('saveorder')">{{'Save'|translate}}</button>
                                    <button class="btn btn-outline-danger ms-3" (click)="saveDetails('placeorder')"
                                        [disabled]="lockerNotFree">{{'placeAnOrder'|translate}}</button>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </form>
</div>


<div id="contentToConvert">

    <p>{{selectedLocationname}}</p>
    <ngx-barcode6 [bc-value]="partenOrderForm.value.barcode" [bc-display-value]="true"></ngx-barcode6>
</div>
