import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { AuthorizationService } from '../../Service/authorization.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PostmanLoginCodeComponent } from 'src/app/modals/postman-login-code/postman-login-code.component';
import {Subscription, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/Service/user.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  onlineEvent: Subscription;
  offlineEvent: Subscription;
  subscriptions: Subscription[] = [];
  public userInfo;
  public authorized: boolean;
  public email: string;
  bsModalRef: BsModalRef;
  showDeliverypointHeader = true;
  showparcelHeader = true;
  showUserHeader = true;
  showPostman = true;
  showReportHeader = true;
  showpartnerHeader = true;
  loading: any;
  showSpinner = false;
  partnerId: number = null;
  networkStatus = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  constructor(
    public _authorizationService: AuthorizationService,
    public _user: UserService,
    public _router: Router,
    private readonly _modalService: BsModalService,
    private readonly _toastr: ToastrService,
    private readonly _spinner: SpinnerService,
    private readonly _translate: TranslateService
  ) {
    this._spinner.showSpinner.subscribe(value => {
      this.showSpinner = value;
    });
    this._user.subject.subscribe(value => {
      if (value) {
        this.getMenuVisibility();
      }
    });
    this.checkNetworkStatus();
  }

  ngOnInit() {
    this._authorizationService.currentDdsUser.subscribe(user => {
      if (user) {
        this.authorized = true;
        this.userInfo = user;
        this.email = this.userInfo.Email || this.userInfo.email;
        this.partnerId = user?.partner?.id;
        this.getMenuVisibility();
      } else {
        this.authorized = false;
      }
    });
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        if (this.networkStatus !== status) {
          if (status) {
            this._toastr.success(this._translate.instant('You are back Online!'));
          } else {
            this._toastr.error(this._translate.instant('No internet connection!'));
          }
        }
        this.networkStatus = status;
      });
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }

  postmanLogin() {
    const initialState = {};
    const modalRef = this._modalService.show(PostmanLoginCodeComponent, {
      initialState,
      backdrop: 'static',
      keyboard: true,
      class: 'modal-md'
    });
    (modalRef.content.onClose).subscribe((res) => {
    });
  }

  getMenuVisibility(){
    if(this.userInfo?.permissions){
      //deliverypoint
      let delPermission = this.userInfo?.permissions?.deliveryPoints;
      let staffPermission = this.userInfo?.permissions?.staff;
      this.showDeliverypointHeader = delPermission?.showList || delPermission?.showOnboard || (staffPermission?.postmanCode && this.partnerId == 1);
      //parcels
      let parcelPermission = this.userInfo?.permissions?.parcels;
      this.showparcelHeader = parcelPermission?.list || parcelPermission?.reserveLastmile || parcelPermission?.reserveFirstmile;
      
      //users
      let userPermission = this.userInfo?.permissions?.users;
      this.showUserHeader = userPermission?.showList || userPermission?.addUser || userPermission?.showGroup || userPermission?.postmanCode;
      
      //reports
      let reportPermission = this.userInfo?.permissions?.report;
      this.showReportHeader = this.partnerId == 1 && (reportPermission?.downTimeReport || reportPermission?.firstmileFailed || reportPermission?.userOpsReport);
    
      //partners
      let partnerPersmission = this.userInfo?.permissions?.partner;
      this.showpartnerHeader = this.partnerId == 1 && (partnerPersmission?.showList || partnerPersmission?.addPartner);
    }
  }

  signOut() {
    this._authorizationService.signOut();
    localStorage.removeItem('currentLang');
  }
}
